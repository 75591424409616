import { LoginData } from './../models/datas/login.data';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { NavigationStart, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuth);
  authStatus: boolean = this.isAuth;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if(this.authStatus != this.isAuth){
            this.markStatusChange()
          }
        }
      });
  }

  login(data: { username: string, password: string }): Observable<LoginData> {
    
    const data1 = {
      identifier: data.username,
      password: data.password
    };
    // let headers;
    // const token = this.localStorageService.get(LocalStorageEnum.token);
    // if (token) {
    //   headers = new HttpHeaders({
    //     Authorization: 'Bearer ' + token
    //   });
    // }
    // else{
    //   headers = new HttpHeaders();
    // }
    // headers.append('Content-Type', 'application/json');
    // return this.http.post<LoginData>(environment.api_url + '/auth/authenticate', data1, { headers }).pipe(
    //   map(res => {
    //     const token = res['data'].token;
    //     const refresh_token = res['data'].refresh_token;
    //     const userId = res['data'].user._id;

    //     this.localStorageService.set(LocalStorageEnum.token, token);
    //     this.localStorageService.set(LocalStorageEnum.refresh_token, refresh_token);
    //     this.localStorageService.set(LocalStorageEnum.user_id, userId);
    //     this.authChange$.next(true);
    //     return res;
    //   })
    // );

    return this.requestService.postJSON<LoginData>('/auth/authenticate', { data: data1 }).pipe(
      map(res => {

        const token = res.token;
        const refresh_token = res.refresh_token;
        const userId = res.user._id;
        const roles = res.user.roles;

        this.localStorageService.set(LocalStorageEnum.token, token);
        this.localStorageService.set(LocalStorageEnum.refresh_token, refresh_token);
        this.localStorageService.set(LocalStorageEnum.user_id, userId);
        this.localStorageService.setArray(LocalStorageEnum.user_roles, roles);
        this.authChange$.next(true);

        return res;
      })
    );
  }

  logout(): Observable<string> {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    return new Observable<string>(observer => {
      observer.complete(); // complete function will be called when the observable is complete
      this.markStatusChange()
    });
  }

  private markStatusChange(){
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }

  get isAuth(): boolean {
    return this.localStorageService.get(LocalStorageEnum.token) ? true : false;
  }
}