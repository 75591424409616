import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { RequestParam } from '../models/request-param';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<Profile> {

    constructor(injector: Injector) {
        super(injector);
        this.path = '/auth/authenticate-jwt';
    }

    getProfile(data: RequestParam){
        return this.requestService.getJSON<Profile>(
            this.path, data
        );
    }
}