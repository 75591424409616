import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.getProfile();
  }

  profile: Profile;
  token: string = '';

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  changeLanguage(lang: string){
    this.translateService.use(lang);
  }

  getProfile(){
    this.profileService.getProfile({}).subscribe(
      (res) => {
        this.profile = res;
      }
    );
  }
}
