<!-- <div class="app-menu">
    <div class="app-container">
        <mat-toolbar id="dashboard">
            <header class="dashboard__header">
                <h1>KAS Satellite Admin</h1>
                <div class="header-action">
                    <div class="action">
                            <button mat-icon-button>
                                 <mat-icon  svgIcon="chat_icon"></mat-icon>
                            </button>
                            <button mat-icon-button>
                                 <mat-icon svgIcon="notification"></mat-icon>
                            </button>
                        <div class="icon item vertical-line"></div>
                    </div>
                    <div class="avtar">
                        <div>
                            <button mat-button [matMenuTriggerFor]="beforeMenu" class=" profile-menu-btn">
                                Admin
                                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <button mat-menu-item> <mat-icon>vpn_key</mat-icon>account</button>
                                <button mat-menu-item> <mat-icon>logout</mat-icon> Logout</button>
                            </mat-menu>
                        </div>
                        <img mat-card-avatar class="avtar-icon" [src]="" onerror="this.src='/assets/imgs/avatar-person.svg'"/>
                    </div>
                    <div class="avtar" *ngIf="account$ | async as account ">
                        <div>
                            <button mat-button [matMenuTriggerFor]="beforeMenu" class=" profile-menu-btn">
                                {{ account }}
                                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <button mat-menu-item> <mat-icon>vpn_key</mat-icon>account</button>
                                <button mat-menu-item> <mat-icon>logout</mat-icon> Logout</button>
                            </mat-menu>
                        </div>
                        <img mat-card-avatar class="avtar-icon" [src]="account.profile_image" onerror="this.src='/assets/imgs/avatar-person.svg'"/>
                    </div>
    
                </div>
            </header>
        </mat-toolbar>
        <section class="dash-row ">
            <router-outlet></router-outlet>
        </section>
    </div>
</div> -->
<div class="app-menu">
    <div class="app-root-content">
        <mat-toolbar id="dashboard">
            <header class="dashboard__header">
                <h1>{{ 'AppTitle' | translate }}</h1>
                <div class="header-action">
                    <button mat-button [routerLink]="['/home']">
                        <mat-icon>apps</mat-icon>
                        {{ 'AllApps' | translate }}
                    </button>
                    <div class="action">
                        <button mat-icon-button [matMenuTriggerFor]="langMenu">
                            <mat-icon>language</mat-icon>
                        </button>
                        <mat-menu #langMenu="matMenu" xPosition="before" style="text-align: left;">
                            <button mat-menu-item (click)="changeLanguage('kh')">
                                <mat-icon>
                                    <img src="../../../assets/flag/flag-sm-kh.png">
                                </mat-icon>
                                ភាសាខ្មែរ
                            </button>
                            <button mat-menu-item (click)="changeLanguage('en')">
                                <mat-icon style="width: 30px; height: 30px;">
                                    <img src="../../../assets/flag/flag-sm-us.png">
                                </mat-icon>
                                English
                            </button>
                        </mat-menu>
                        <!-- <button mat-icon-button>
                            <mat-icon svgIcon="notification"></mat-icon>
                        </button> -->
                        <div class="icon item vertical-line"></div>
                    </div>
                    <div class="avtar">
                        <!-- <div>
                            <button mat-icon-button [matMenuTriggerFor]="beforeMenu" class="profile-menu-btn">
                                <img mat-card-avatar class="avtar-icon" [src]="'/assets/imgs/avatar-person.svg'" onerror="this.src='/assets/imgs/avatar-person.svg'"/>
                            </button>
                            <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <button mat-menu-item><mat-icon>vpn_key</mat-icon>{{ 'Profile' | translate }}</button>
                                <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>{{ 'Logout' | translate }}</button>
                            </mat-menu>
                        </div> -->

                        <div>
                            <button mat-button [matMenuTriggerFor]="beforeMenu" class="profile-menu-btn">
                                <img mat-card-avatar class="avtar-icon"
                                    [src]="profile?.user?.photo_url ? profile?.user?.photo_url + '?token=' + token : '/assets/imgs/avatar-person.svg'"
                                    onerror="this.src='/assets/imgs/avatar-person.svg'" />
                                    {{ profile?.user | fullName:''}}
                                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #beforeMenu="matMenu" xPosition="before">
                                <button mat-menu-item routerLinkActive="router-link-active">
                                    <mat-icon>vpn_key</mat-icon>
                                    {{ 'Profile' | translate }}
                                </button>
                                <button mat-menu-item (click)="logout()">
                                    <mat-icon>logout</mat-icon>
                                    {{ 'Logout' | translate }}
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </header>
        </mat-toolbar>
        <section class="dash-row">
            <router-outlet></router-outlet>
        </section>
    </div>
</div>