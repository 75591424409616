<mat-dialog-content align="center">
  <div class="alert-icon-dialog">
    <img [src]="icon" alt="icon.svg" />
  </div>
  <h2 matDialogTitle>{{ title | translate }}</h2>
  <!-- <p>{{ message }}</p> -->
  <p>{{ message | translate: param }}</p>
</mat-dialog-content>
<mat-dialog-actions class="btn-action-dialog">
    <button mat-raised-button matDialogClose="cancel" class="btn-cancel">
        <mat-icon>cancel</mat-icon>
        {{ 'Cancel' | translate }}
    </button>
    <button *ngIf="button === 'delete'" mat-raised-button matDialogClose="confirm" color="warn" class="btn-delete">
        <mat-icon>delete</mat-icon>
        {{ 'Delete' | translate }}
    </button>
    <button *ngIf="button === 'save'" mat-raised-button matDialogClose="confirm" color="warn" class="btn-save">
        <mat-icon>save</mat-icon>
        {{ 'Save' | translate }}
    </button>
    <button *ngIf="button === 'confirm'" mat-raised-button matDialogClose="confirm" color="warn" class="btn-save">
        <mat-icon>check_circle_outline</mat-icon>
        {{ 'Confirm' | translate }}
    </button>
</mat-dialog-actions>
